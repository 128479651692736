import React, { useEffect, useState } from 'react';
import {
  DefaultLayout,
  WCIntro,
  WCGetAround,
  WCTravel,
  WCCommute,
  WCCommutes,
  WCWelcome
} from '../components';
import NotFound from './NotFound';
import { client } from '../utils/prismicHelpers';

import { Helmet } from "react-helmet";

// connected component
//
const Connected = ({ match }) => {
  const [prismicData, setPrismicData] = useState({ connDoc: null, menuDoc: null });
  const [notFound, toggleNotFound] = useState(false);

  const uid = match.params.uid;

  // Get the page document from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const connDoc = await client.getByUID('connected', uid);
        const menuDoc = await client.getSingle('menu');

        if (connDoc) {
          setPrismicData({ connDoc, menuDoc });
        } else {
          console.warn('Connected document was not found. Make sure it exists in your Prismic repository');
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    }
    fetchPrismicData();

    // Load new page at the top (when linking from the middle of another page)
    window.scrollTo(0, 0);
  }, [uid]);

  // Return the page if a document was retrieved from Prismic
  if (prismicData.connDoc) {
    const connDoc = prismicData.connDoc;
    const menuDoc = prismicData.menuDoc;

    return (
      <DefaultLayout
        wrapperClass="connected"
        menuDoc={menuDoc}
      >
        <Helmet>
          <title data-react-helmet="true">{((connDoc.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((connDoc.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />

          <meta
            itemprop="name"
            content="Harbor Bay"
            data-react-helmet="true"
          />
          <meta
            itemprop="description"
            content={((connDoc.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="image"
            content={(connDoc.data.meta_image || {}).url}
            data-react-helmet="true"
          />

          <meta property="og:url" content={`https://www.harborbay.com/about/${uid}`} data-react-helmet="true" />
          <meta property="og:type" content="website" data-react-helmet="true" />
          <meta
            property="og:title"
            content={((connDoc.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((connDoc.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={(connDoc.data.meta_image || {}).url}
            data-react-helmet="true"
          />

          <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
          <meta
            name="twitter:title"
            content={((connDoc.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={((connDoc.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={(connDoc.data.meta_image || {}).url}
            data-react-helmet="true"
          />
        </Helmet>
        <WCIntro intro={connDoc.data.intro[0]} />
        <WCGetAround around={connDoc.data.around[0]} />
        <WCTravel travel={connDoc.data.travel[0]} />
        <WCCommute commute={connDoc.data.commute[0]} />
        <WCCommutes commutes={connDoc.data.commutes} />
        <WCWelcome welcome={connDoc.data.welcome[0]} />
      </DefaultLayout>
    );
  } else if (notFound) {
    return <NotFound />;
  }
  return null;
}

export default Connected
