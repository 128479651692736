import React from 'react';
import { RichText } from 'prismic-reactjs';

// business intro component
//
const BizIntro = ({ intro }) => (

  <section className="intro">
    <div className="container">
      <div className="row pt-4 pt-md-0">
        <div className="col-8">
          <h1 className="business--name">{RichText.asText(intro.name)}</h1>
        </div>
        <div className="col-4 text-right">
          <figure>
            <img className="section--icon business" src={intro.icon.url} alt={intro.icon.alt} />
          </figure>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10">
          <h3 className="py-3 py-md-5">{RichText.asText(intro.tagline)}</h3>
        </div>
      </div>
    </div>
  </section>

);

export default BizIntro;
