import React from 'react';
import { RichText } from 'prismic-reactjs';

//
// homepage wwm component
const HomepageWWM = ({ wwm }) => (

  <div className="container">
    <div className="row">
      <div className="col-8">
        <h3 className="py-2 py-md-5">{RichText.asText(wwm.header)}</h3>
      </div>
      <div className="col-4 text-right">
        <img className="section--icon" src={wwm.wwm_icon.url} alt={wwm.wwm_icon.alt} />
      </div>
    </div>
    <div className="row">
      <div className="col-md-8">
        <p className="py-4 lead--in">{RichText.asText(wwm.wwm_description)}</p>
      </div>
    </div>
  </div>

);

export default HomepageWWM;
