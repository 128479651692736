import React from 'react';
import { RichTextField } from '../prismic-elements';

/**
 * Text section slice component
 */
const TextSection = ({ slice }) => {
  const sectionClass = slice.slice_label ? `text-section-${slice.slice_label}` : 'text-section-1col';
  return (
    <section className={`container slice-section block--text ${sectionClass}`}>
      <div class="row justify-content-end">
        <div class="col-md-8">
          <RichTextField field={slice.primary.copy} />
        </div>
      </div>
    </section>
  );
};

export default TextSection;
