import React from 'react';
import { RichText } from 'prismic-reactjs';

// full width img slice
//
const FullWidthImage = ({ slice }) => (
  <section className="full--width">
    <figure>
      <img className="img-fluid" src={slice.primary.image.url} alt={slice.primary.image.alt} />
    </figure>
    <div className="container">
      <div className="row">
        <div className="col">
          {RichText.render(slice.primary.detail_list)}
        </div>
      </div>
    </div>
  </section>
);

export default FullWidthImage;
