import React from 'react';
import { RichText } from 'prismic-reactjs';
import { PrismicLink, RichTextField } from '../prismic-elements';

// tenant list slice component
//
const LinkList = ({ slice }) => {
  return (
    <section className="resource-list slice-section">
      <div className="container">
        <div className="row">
          <div className="col-8">
            <RichTextField field={slice.primary.title} />
            <div className="pt-5">
            {slice.items.map((item, index) => (
              <Resource item={item} key={index} />
            ))}
            </div>
          </div>
          <div className="col-4">
          <figure>
            <img className="section--icon" src={slice.primary.icon.url} alt={slice.primary.icon.alt} />
          </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

// resource item
//
const Resource = ({ item }) => {
  return (
    <div className="pb-3 pb-md-4">
      <PrismicLink link={item.url}>
        {RichText.asText(item.label)}
      </PrismicLink>
    </div>
  );
};

export default LinkList;
