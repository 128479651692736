import React from 'react';
import { RichText } from 'prismic-reactjs';

// quote slice
//
const Quote = ({ slice }) => {

  return (
    <section className="pullquote slice-section">
      <div className={RichText.asText(slice.primary.background_color)}>
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <blockquote>
                <h3>{RichText.asText(slice.primary.quote)}</h3>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Quote;
