import React from 'react';
import { RichText } from 'prismic-reactjs';
import { PrismicLink } from '../prismic-elements';

// site footer
//
const Footer = ({ menuDoc }) => {
  if (menuDoc) {
    return (
      <footer id="footer">
        <aside id="directory" className="d-none">
          <div className="container">
            <div className="row">
              <div className="col text-center py-5">
                <a className="learn--more" href="/directory">See the full directory of businesses at Harbor Bay</a>
              </div>
            </div>
          </div>
        </aside>
        <aside id="callout">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                {RichText.render(menuDoc.data.callout)}
              </div>
              <div className="col-md-4">
                <a className="btn--arrow float-md-right mt-4 mt-md-0" href={`mailto:${RichText.asText(menuDoc.data.contact_email)}?Subject=Harbor Bay Tour`}>Contact Us</a>
              </div>
            </div>
          </div>
        </aside>
        <div className="container">
          <div className="row">
            <div className="col-md-5 order-md-3 pt-2 py-3 py-md-0">
              <h6 className="pb-3">Contact</h6>
              <address>
                {RichText.render(menuDoc.data.contact_info)}
              </address>
            </div>
            <div className="col-md-3 order-md-2 pt-2 py-3 py-md-0">
              {menuDoc.data.footer_links.map(footerLink => (
                <FooterLink
                  footerLink={footerLink}
                  key={footerLink.link_url.id}
                />
              ))}
            </div>
            <div className="col-md-4 order-md-1 py-3 py-md-0">
              <figure className="logo">
                <img className="img-fluid" src="/images/logo-harbor-bay-white.svg" alt="Harbor Bay logo" />
              </figure>
            </div>
          </div>
        </div>
      </footer>
    );
  }
  return null;
};

// footer link component
//
const FooterLink = ({ footerLink }) => {
  return (
    <h6>
      <PrismicLink
        link={footerLink.link_url}
        class="pb-3"
      >
        {RichText.asText(footerLink.link_name)}
      </PrismicLink>
    </h6>
  );
};


export default Footer;
