import React from 'react';
import { RichText } from 'prismic-reactjs';

// connected intro component
//
const WCIntro = ({ intro }) => (

  <section className="intro pt-3 pt-md-0">
    <div className="container">
      <div className="row">
        <div className="col-8">
          <h3 className="py-3 py-md-5">{RichText.asText(intro.title)}</h3>
        </div>
        <div className="col-4 text-right">
          <figure>
            <img className="section--icon" src={intro.icon.url} alt={intro.icon.alt} />
          </figure>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <p className="lead--in py-3 py-md-5">{RichText.asText(intro.lead_in)}</p>
        </div>
      </div>
    </div>
    <figure className="pt-2 pt-md-5 mb-0">
      <img className="img-fluid" src={intro.full_width_image.url} alt={intro.full_width_image.alt} />
    </figure>
  </section>

);

export default WCIntro;
