import React from 'react';
import { RichText } from 'prismic-reactjs';

// connected commute component
//
const WCCommute = ({ commute }) => (

  <section className="commute">
    <div className="container">
      <div className="row pt-5 pt-md-0">
        <div className="col-8">
          <h3 className="py-3 py-md-5">{RichText.asText(commute.title)}</h3>
        </div>
        <div className="col-4 text-right">
          <img className="section--icon" src={commute.icon.url} alt={commute.icon.alt} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <p className="py-3 py-md-5">{RichText.asText(commute.details)}</p>
        </div>
      </div>
    </div>
  </section>

);

export default WCCommute;
