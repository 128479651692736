import React from 'react';
import { Footer, Header } from './';

// default site layout
//
const DefaultLayout = ({ wrapperClass, menuDoc, children }) => {
  return (
    <div className="wrapper">
      <div className={wrapperClass}>
        <Header menuDoc={menuDoc} />
        {children}
        <Footer menuDoc={menuDoc} />
      </div>
    </div>
  );
}

export default DefaultLayout;
