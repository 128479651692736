import React from 'react';
//import { RichText } from 'prismic-reactjs';
import { RichTextField } from '../prismic-elements';

// tenant list slice component
//
const TenantList = ({ slice }) => {
  return (
    <section className="tenant-list content-section">
      <hr />
      <div className="container">
        <div className="row">
          <div className="col">
            <RichTextField field={slice.primary.title} />
          </div>
        </div>
      </div>
      <div className="container tenants">
        {slice.items.map((item, index) => (
          <Tenant item={item} key={index} />
        ))}
      </div>
    </section>
  );
};

// tenant item
//
const Tenant = ({ item }) => {
  return (
    <div className="row">
      <div className="col-md-5 pb-1 pb-md-0">
        <RichTextField field={item.name} />
      </div>
      <div className="col-md-5 pb-1 pb-md-0">
        <RichTextField field={item.address} />
      </div>
      <div className="col-md-2 text-md-right">
        <RichTextField field={item.phone} />
      </div>
    </div>
  );
};

export default TenantList;
