import React from 'react';
import { RichText } from 'prismic-reactjs';
import { RichTextField } from '../prismic-elements';

// tenant list slice component
//
const ServiceList = ({ slice }) => {
  const introText = slice.primary.title;
  const featImg = slice.primary.featured_image.url;

  return (
    <section className="service-list slice-section">
      <hr />
      {featImg && (
      <div className="full--img" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${featImg})` }}>
      </div>
      )}
      {introText.length > 0 && (
      <div className="container">
        <div className="row pt-4 pt-md-5">
          <div className="col">
            <RichTextField field={slice.primary.title} />
          </div>
        </div>
      </div>
      )}
      <div className="container">
        {slice.items.map((item, index) => (
          <Service item={item} key={index} />
        ))}
      </div>
    </section>
  );
};

// tenant item
//
const Service = ({ item }) => {
  const featuredImage = item.image.url;

  return (
    <div className="row py-5">
      {featuredImage && (
      <div className="col-md-4 order-md-2">
        <img className="img-fluid mb-4 mb-md-0 mt-md-5" src={featuredImage} alt={item.image.alt} />
      </div>
      )}
      <div className="col-md-8 order-md-1">
        {RichText.render(item.row_title)}
        {RichText.render(item.service_description)}
      </div>
    </div>
  );
};

export default ServiceList;
