import React from 'react';
import { RichText } from 'prismic-reactjs';

// page intro component
//
const PageIntro = ({ intro }) => (

  <section className="intro">
    <div className="container">
      <div className="row align-items-center pt-4 pt-md-0">
        <div className="col-8">
          <h1>{RichText.asText(intro.title)}</h1>
        </div>
        <div className="col-4 text-right">
          <figure>
            <img className="section--icon business" src={intro.icon.url} alt={intro.icon.alt} />
          </figure>
        </div>
      </div>
    </div>
    <div className="sub" data-bg-color={intro.background}>
      <div className="container">
        <div className="row pt-2 pt-md-3">
          <div className="col-md-12">
            <p className="lead--in py-3 py-md-5">{RichText.asText(intro.subheader)}</p>
            {RichText.render(intro.featured_list)}
          </div>
        </div>
      </div>
    </div>
  </section>

);

export default PageIntro;
