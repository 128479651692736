import React from 'react';
import { Link, RichText } from 'prismic-reactjs';


// connected featured commutes component
//
const WCCommutes = ({ commutes }) => {
  return (
    <section className="featured-commutes">
      <div id="carouselCommutes" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          {commutes.map((item, index) => (
          <FeaturedCommute item={item} key={index} />
          ))}
        </div>
        <ol className="carousel-indicators">
          <li data-target="#carouselCommutes" data-slide-to="0" className="active"></li>
          <li data-target="#carouselCommutes" data-slide-to="1"></li>
          <li data-target="#carouselCommutes" data-slide-to="2"></li>
        </ol>
      </div>
    </section>
  );
};

// featured commute component
//
const FeaturedCommute = ({ item }) => {
  return (
    <div className={RichText.asText(item.active_class)}>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-7 col-lg-5">
            <div className="commute--data p-4 p-md-5">
              <h6 className="pb-3 pb-md-5">{RichText.asText(item.subhead)}</h6>
              <h4 className="pb-3 pb-md-5">{RichText.asText(item.title)}</h4>
              <a className="learn--more pb-3 pt-3 pb-md-0 pt-md-5" href={Link.url(item.url)}>Map it</a>
            </div>
          </div>
          <div className="col-md-5 col-lg-5">
            <figure className="mt-n1 mt-md-0">
              <img className="img-fluid" src={item.image.url} alt={item.image.alt} />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WCCommutes;
