import React from 'react';
import { RichText } from 'prismic-reactjs';
import { PrismicLink } from './prismic-elements';

// homepage featured businesses component
//
const HomeBusinesses = ({ businesses }) => {
  return (
    <section className="featured--businesses pt-5 d-none">
      <div className="bg-mint"></div>
      <div className="container mb-2">
        <div className="row">
          {businesses.map((item, index) => (
            <FeaturedBiz item={item} key={index} />
          ))}
        </div>
      </div>
      <aside className="directory mt-5">
        <div className="container">
          <div className="row">
            <div className="col text-center py-5">
              <a className="learn--more" href="/directory">See the full directory of businesses at Harbor Bay</a>
            </div>
          </div>
        </div>
      </aside>
    </section>
  );
};

// latest news component
//
const FeaturedBiz = ({ item }) => {
  return (
      <div className="col-md-4">
      <PrismicLink
        link={item.link_url}
        linkClass="featured--link"
      >
        <img className="img-fluid" src={item.image.url} alt={item.image.alt} />
        {RichText.asText(item.link_text)}
        <span className="btn--arrow btn--arrow-sm mt-2"></span>
      </PrismicLink>
      </div>
  );
};

export default HomeBusinesses;
