import React from 'react';
import { RichText } from 'prismic-reactjs';

// homepage wwm gallery component
//
const HomeWWMGallery = ({ wwmgallery }) => {
  return (
    <section className="image-gallery">
      <div className="container">
        <div className="row">
        {wwmgallery.map((item, index) => (
          <GalleryItem item={item} key={index} />
        ))}
        </div>
      </div>
    </section>
  );
};

// gallery item component
//
const GalleryItem = ({ item }) => {
  return (
    <div className="col-md-4 gallery-item">
      <figure>
        <img className="img-fluid" src={item.gallery_image.url} alt={item.gallery_image.alt} />
        <figcaption className="sr-only">{RichText.asText(item.image_description)}</figcaption>
      </figure>
    </div>
  );
};

export default HomeWWMGallery;
