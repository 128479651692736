import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';
import { HomePage, NotFound, Page, Connected, Business, Preview } from './pages';
import { apiEndpoint } from './prismic-configuration';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './scss/styles.scss';

const App = () => {
  const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint);
  const repoName = repoNameArray[1];

  return (
    <Fragment>
      <Helmet>
        <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`} />
      </Helmet>
      <BrowserRouter>
        <Route render={({location}) => (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={450}
              classNames="fade"
            >
              <Switch location={location}>
                <Route exact path='/' component={HomePage} />
                <Route exact path='/preview' component={Preview} />
                <Route exact path='/:uid' component={Page} />
                <Route exact path='/about/:uid' component={Connected} />
                <Route exact path='/featured-business/:uid' component={Business} />
                <Route component={NotFound} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )} />
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
