import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';

// react link for prismic links
//
const PrismicLink = ({ link, linkClass, children }) => {
  const linkUrl = Link.url(link, linkResolver);

  if (/^https?:\/\//.test(linkUrl)) {
    return <a className={linkClass} href={linkUrl} target="_blank" rel="noopener noreferrer">{children}</a>
  }

  if (linkUrl && children !== '') {
    return (
      <RouterLink
        className={linkClass}
        to={linkUrl}
      >
        {children}
      </RouterLink>
    );
  }

  return null;
}

export default PrismicLink;
