import React from 'react';
import { RichText } from 'prismic-reactjs';

// homepage featured component
//
const HomeFeatured = ({ featured }) => (

  <section className="featured d-none">
    <div className="container">
      <div className="row">
        <div className="col-8">
          <h3 className="py-3 py-md-5">{RichText.asText(featured.title)}</h3>
        </div>
        <div className="col-4 text-right">
          <img className="section--icon" src={featured.icon.url} alt={featured.icon.alt} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <p className="py-3 py-md-5">{RichText.asText(featured.description)}</p>
        </div>
      </div>
    </div>
  </section>

);

export default HomeFeatured;
