import React from 'react';
import { RichText } from 'prismic-reactjs';

// homepage map component
//
const HomeMap = ({ map }) => (

  <div className="container">

    <div className="row pt-3 pt-md-0">
      <div className="col-8">
        <h3 className="py-0 py-md-5">{RichText.asText(map.heading)}</h3>
      </div>
      <div className="col-4 text-right">
        <img className="section--icon" src={map.room_icon.url} alt={map.room_icon.alt} />
      </div>
    </div>

    <div className="row py-3">
      <div className="col-md-10">
        {RichText.render(map.description)}
      </div>
    </div>

    <div className="row map--full mt-5">
      <div className="col">
        <figure>
          <img className="img-fluid" src={map.full_width_image.url} alt={map.full_width_image.alt} />
        </figure>
      </div>
    </div>

    <div className="row full--details">
      <div className="col">
        {RichText.render(map.details)}
      </div>
    </div>

    <div className="row mt-2">
      <div className="col-6 col-md-3 col-lg-4">
        <img className="img-fluid" src={map.featured_image_1.url} alt={map.featured_image_1.alt} />
      </div>
      <div className="col-6 col-md-3 col-lg-4">
        <img className="img-fluid" src={map.featured_image_2.url} alt={map.featured_image_2.alt} />
      </div>
      <div className="col-12 col-md-6 col-lg-4">
        <div className="color--block bg--peach mt-4 mt-md-0">
          {RichText.render(map.amenities)}
        </div>
      </div>
    </div>

  </div>

);

export default HomeMap;
