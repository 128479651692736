import React, { useEffect, useState } from 'react';
import {
  DefaultLayout,
  BizIntro,
  SliceZone
} from '../components';
import NotFound from './NotFound';
import { client } from '../utils/prismicHelpers';

import { Helmet } from "react-helmet";

// business component
//
const Business = ({ match }) => {
  const [prismicData, setPrismicData] = useState({ businessDoc: null, menuDoc: null });
  const [notFound, toggleNotFound] = useState(false);

  const uid = match.params.uid;

  // Get the page document from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const businessDoc = await client.getByUID('business', uid);
        const menuDoc = await client.getSingle('menu');

        if (businessDoc) {
          setPrismicData({ businessDoc, menuDoc });
        } else {
          console.warn('Page document was not found. Make sure it exists in your Prismic repository');
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    }
    fetchPrismicData();

    // Load new page at the top (when linking from the middle of another page)
    window.scrollTo(0, 0);
  }, [uid]);

  // Return the page if a document was retrieved from Prismic
  if (prismicData.businessDoc) {
    const businessDoc = prismicData.businessDoc;
    const menuDoc = prismicData.menuDoc;

    return (
      <DefaultLayout
        wrapperClass="business"
        menuDoc={menuDoc}
      >
        <Helmet>
          <title data-react-helmet="true">{((businessDoc.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((businessDoc.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />

          <meta
            itemprop="name"
            content="Harbor Bay"
            data-react-helmet="true"
          />
          <meta
            itemprop="description"
            content={((businessDoc.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="image"
            content={(businessDoc.data.meta_image || {}).url}
            data-react-helmet="true"
          />

          <meta property="og:url" content={`https://www.harborbay.com/featured-business/${uid}`} data-react-helmet="true" />
          <meta property="og:type" content="website" data-react-helmet="true" />
          <meta
            property="og:title"
            content={((businessDoc.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((businessDoc.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={(businessDoc.data.meta_image || {}).url}
            data-react-helmet="true"
          />

          <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
          <meta
            name="twitter:title"
            content={((businessDoc.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={((businessDoc.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={(businessDoc.data.meta_image || {}).url}
            data-react-helmet="true"
          />
        </Helmet>
        <BizIntro intro={businessDoc.data.intro[0]} />
        <SliceZone sliceZone={businessDoc.data.body} />
      </DefaultLayout>
    );
  } else if (notFound) {
    return <NotFound />;
  }
  return null;
}

export default Business
