import React from 'react';
//import { RichText } from 'prismic-reactjs';
import { RichTextField } from '../prismic-elements';

// two col text slice component
//
const TwoColText = ({ slice }) => {
  return (
    <section className="two-col-text slice-section">
      <div className="container">
        <div className="row">
          <div className="col">
            <RichTextField field={slice.primary.title} />
          </div>
        </div>
        <div className="row pt-2 pt-md-5">
          <div className="col-md-5">
            <RichTextField field={slice.primary.left_column_content} />
          </div>
          <div className="col-md-2 text-center">
            <span className="barrier"></span>
          </div>
          <div className="col-md-5">
            <RichTextField field={slice.primary.right_column_content} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TwoColText;
