import React, { useEffect, useState } from 'react';
import {
  DefaultLayout,
  HomepageBanner,
  HomepageWWM,
  HomeWWMGallery,
  HomeMap,
  HomeHub,
  HomeFeatured,
  HomeBusinesses,
  HomeLatest,
} from '../components';
import NotFound from './NotFound';
import { client } from '../utils/prismicHelpers';

import { Helmet } from "react-helmet";

// homepage components
//
const HomePage = () => {
  const [prismicData, setPrismicData] = useState({ homeDoc: null, menuDoc: null });
  const [notFound, toggleNotFound] = useState(false);

  // Get the homepage document from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const homeDoc = await client.getSingle('homepage');
        const menuDoc = await client.getSingle('menu');

        if (homeDoc) {
          setPrismicData({ homeDoc, menuDoc });
        } else {
          console.warn('Homepage document was not found. Make sure it exists in your Prismic repository.');
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    }

    fetchPrismicData();
  }, []);

  // Return the page if a document was retrieved from Prismic
  if (prismicData.homeDoc) {
    const homeDoc = prismicData.homeDoc;
    const menuDoc = prismicData.menuDoc;

    return (
      <DefaultLayout
        wrapperClass="homepage"
        menuDoc={menuDoc}
      >
        <Helmet>
          <title data-react-helmet="true">{((homeDoc.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((homeDoc.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />

          <meta
            itemprop="name"
            content="Harbor Bay"
            data-react-helmet="true"
          />
          <meta
            itemprop="description"
            content={((homeDoc.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="image"
            content={(homeDoc.data.meta_image || {}).url}
            data-react-helmet="true"
          />

          <meta property="og:url" content="https://www.harborbay.com" data-react-helmet="true" />
          <meta property="og:type" content="website" data-react-helmet="true" />
          <meta
            property="og:title"
            content={((homeDoc.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((homeDoc.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={(homeDoc.data.meta_image || {}).url}
            data-react-helmet="true"
          />

          <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
          <meta
            name="twitter:title"
            content={((homeDoc.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={((homeDoc.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={(homeDoc.data.meta_image || {}).url}
            data-react-helmet="true"
          />
        </Helmet>
        <HomepageBanner banner={homeDoc.data.homepage_banner[0]} />
        <section className="where-we-work section--content">
          <div className="bg-peach"></div>
          <HomepageWWM wwm={homeDoc.data.where_work_matters[0]} />
          <HomeWWMGallery wwmgallery={homeDoc.data.wwm_gallery} />
        </section>
        <HomeMap map={homeDoc.data.map_area[0]} />
        <HomeHub hub={homeDoc.data.home_hub[0]} />
        <HomeFeatured featured={homeDoc.data.featured[0]} />
        <HomeBusinesses businesses={homeDoc.data.businesses} />
        <HomeLatest latest={homeDoc.data.home_latest} />
      </DefaultLayout>
    );
  } else if (notFound) {
    return <NotFound />;
  }
  return null;
};

export default HomePage;
