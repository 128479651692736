import React from 'react';
import { RichText } from 'prismic-reactjs';

// connected travel component
//
const WCTravel = ({ travel }) => (

  <section className="travel">
    <div className="container">
      <div className="row">
        <div className="col">
          <h3 className="py-5">{RichText.asText(travel.title)}</h3>
        </div>
      </div>
      <div className="row align-items-center my-5">
        <div className="color--bg color--bg-blue"></div>
        <div className="col-md-5 order-md-2 text-white">
          {RichText.render(travel.block_1)}
        </div>
        <div className="col-md-6 pt-5 pt-md-0 order-md-1">
          <img className="img-fluid" src={travel.image_1.url} alt={travel.image_1.alt} />
        </div>
      </div>
      <div className="row align-items-center my-5">
        <div className="color--bg color--bg-peach"></div>
        <div className="col-md-5 offset-md-1">
          {RichText.render(travel.block_2)}
        </div>
        <div className="col-md-6 pt-5 pt-md-0">
          <img className="img-fluid" src={travel.image_2.url} alt={travel.image_2.alt} />
        </div>
      </div>
      <div className="row align-items-center last my-5">
        <div className="color--bg color--bg-yellow"></div>
        <div className="col-md-5 order-md-2">
          {RichText.render(travel.block_3)}
        </div>
        <div className="col-md-6 pt-5 pt-md-0 order-md-1">
          <img className="img-fluid" src={travel.image_3.url} alt={travel.image_3.alt} />
        </div>
      </div>
    </div>
  </section>

);

export default WCTravel;
