import React from 'react';
import { RichText } from 'prismic-reactjs';
import { PrismicLink } from './prismic-elements';

// homepage hub component
//
const HomeHub = ({ hub }) => (

  <section
    className="bg--yellow"
    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${hub.hub_image.url})` }}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <h3 className="py-3 py-md-5">{RichText.asText(hub.title)}</h3>
          <p className="py-3 py-md-5">{RichText.asText(hub.details)}</p>
          <PrismicLink
            link={hub.link_url}
            linkClass="learn--more"
          >
            {RichText.asText(hub.link_text)}
          </PrismicLink>
          <figure className="featured--image d-block d-sm-none mt-4">
            <img className="img-fluid" src={hub.hub_image.url} alt={hub.hub_image.alt} />
          </figure>
        </div>
      </div>
    </div>
  </section>

);

export default HomeHub;
