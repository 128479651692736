import React from 'react';
import { RichText } from 'prismic-reactjs';
import { PrismicLink } from './prismic-elements';

// connected get around component
//
const WCGetAround = ({ around }) => (

  <section
    className="get-around"
    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${around.image.url})` }}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <h3 className="py-5">{RichText.asText(around.title)}</h3>
          {RichText.render(around.details)}
          <PrismicLink
            link={around.link_url}
            linkClass="learn--more"
          >
            {RichText.asText(around.link_text)}
          </PrismicLink>
          <figure className="featured--image d-block d-sm-none mt-5">
            <img className="img-fluid" src={around.image.url} alt={around.image.alt} />
          </figure>
        </div>
      </div>
    </div>
  </section>

);

export default WCGetAround;
