import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
//import { PrismicLink } from './prismic-elements';

// homepage latest component
//
const HomeLatest = ({ latest }) => {
  return (
    <section className="latest--news container py-5">
      <div className="row">
        <div className="col">
          <h3 className="py-5">Latest from Harbor Bay</h3>
        </div>
      </div>
      {latest.map((item, index) => (
        <LatestItem item={item} key={index} />
      ))}
    </section>
  );
};

// latest news component
//
const LatestItem = ({ item }) => {
  return (
    <div className="row">
      <div className="col">
        <a
          className="link--article ext"
          href={Link.url(item.latest_link_url)}
        >
          {RichText.asText(item.latest_link_title)}
        </a>
      </div>
    </div>
  );
};

export default HomeLatest;
