import React from 'react';
import { RichText } from 'prismic-reactjs';
import { PrismicLink } from './prismic-elements';

/**
 * Homepage banner component
 */
const HomepageBanner = ({ banner }) => (

  <section
    className="homepage-top"
    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${banner.image.url})` }}
  >

    <section className="homepage-banner container pb-5 pt-3 pt-md-0">
      <div className="row">
        <div className="col-md-6">
          <h1 className="py-3 py-md-5">{RichText.asText(banner.title)}</h1>
          <figure className="featured--image d-block d-sm-none mt-n2">
            <img className="img-fluid" src={banner.image.url} alt={banner.image.alt} />
          </figure>
          <p className="py-3 py-md-5">{RichText.asText(banner.tagline)}</p>
          <PrismicLink
            link={banner.button_link}
            linkClass="btn--arrow"
          >
            {RichText.asText(banner.button_label)}
          </PrismicLink>
        </div>
      </div>
    </section>

  </section>
);

export default HomepageBanner;
