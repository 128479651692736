import React from 'react';
import {
  FullWidthImage,
  Quote,
  RightTextBlock,
  TenantList,
  ServiceList,
  TwoColText,
  LinkList
} from './slices';

// slice components
//
const SliceZone = ({ sliceZone }) => (
  <div className="slice">
    {
      sliceZone.map((slice, index) => {
        switch (slice.slice_type) {
          case ('full_width_image'):
            return <FullWidthImage slice={slice} key={`slice-${index}`} />;
          case ('quote'):
            return <Quote slice={slice} key={`slice-${index}`} />;
          case ('right_text_block'):
            return <RightTextBlock slice={slice} key={`slice-${index}`} />;
          case ('tenant_list'):
            return <TenantList slice={slice} key={`slice-${index}`} />;
          case ('service_list'):
            return <ServiceList slice={slice} key={`slice-${index}`} />;
          case ('two_column_text'):
            return <TwoColText slice={slice} key={`slice-${index}`} />;
          case ('link_list'):
            return <LinkList slice={slice} key={`slice-${index}`} />;
          default:
            return null;
        }
      })
    }
  </div>
);

export default SliceZone;
