import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import { PrismicLink } from '../prismic-elements';

// site header & nav
//
const Header = ({ menuDoc }) => {
  if (menuDoc) {

    const hasBizNav = menuDoc.data.business_nav_name;

    return (
      <header id="header">
        <aside id="banner">
          {RichText.render(menuDoc.data.banner_text)}
        </aside>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-6 col-md-4">
            <RouterLink to="/">
              <figure className="logo">
                <img className="img-fluid" src="/images/logo-harbor-bay.svg" alt="Harbor Bay logo" />
              </figure>
            </RouterLink>
            </div>
            <div className="col-6 col-md-8">
              <button className="navbar-toggler btn-hamburger d-block d-sm-none float-right" type="button" data-toggle="collapse" data-target="#navSlider" aria-controls="navSlider" aria-expanded="false" aria-label="Toggle navigation">
                Menu
              </button>
              <nav className="d-none d-md-block">
                <ul className="nav justify-content-end">
                  {menuDoc.data.menu_links.map(menuLink => (
                    <MenuLink
                      menuLink={menuLink}
                      key={menuLink.link.id}
                    />
                  ))}
                  {hasBizNav.length > 0 && (
                  <li className="nav-item dropdown">
                    <button className="nav-link dropdown-toggle" data-toggle="dropdown" type="button" aria-haspopup="true" aria-expanded="false" data-target="">{RichText.asText(menuDoc.data.business_nav_name)}</button>
                    <div className="dropdown-menu">
                    {menuDoc.data.business_links.map(bizLink => (
                      <BizLink
                        bizLink={bizLink}
                        key={bizLink.business_page.id}
                      />
                    ))}
                    </div>
                  </li>
                  )}
                  {menuDoc.data.post_links.map(postLink => (
                    <PostLink
                      postLink={postLink}
                      key={postLink.link.id}
                    />
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <nav id="navSlider" className="collapse navbar-collapse">
          <ul className="nav">
            {menuDoc.data.menu_links.map(menuLink => (
              <MenuLink
                menuLink={menuLink}
                key={menuLink.link.id}
              />
            ))}
            {menuDoc.data.post_links.map(postLink => (
              <PostLink
                postLink={postLink}
                key={postLink.link.id}
              />
            ))}
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="/featured" role="button" aria-haspopup="true" aria-expanded="true">{RichText.asText(menuDoc.data.business_nav_name)}</a>
              <div className="dropdown-menu show">
              {menuDoc.data.business_links.map(bizLink => (
                <BizLink
                  bizLink={bizLink}
                  key={bizLink.business_page.id}
                />
              ))}
              </div>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
  return null;
};

// menu link component
//
const MenuLink = ({ menuLink }) => {
  return (
    <li className="nav-item">
      <PrismicLink
        link={menuLink.link}
        linkClass="nav-link"
      >
        {RichText.asText(menuLink.label)}
      </PrismicLink>
    </li>
  );
};

// post link component
//
const PostLink = ({ postLink }) => {
  return (
    <li className="nav-item">
      <PrismicLink
        link={postLink.link}
        linkClass="nav-link"
      >
        {RichText.asText(postLink.label)}
      </PrismicLink>
    </li>
  );
};

// business link component
//
const BizLink = ({ bizLink }) => {
  return (
    <PrismicLink
      link={bizLink.business_page}
      linkClass="dropdown-item"
    >
      {RichText.asText(bizLink.business_label)}
    </PrismicLink>
  );
};

export default Header;
