import React from 'react';
import { RichText } from 'prismic-reactjs';

// connected welcome component
//
const WCWelcome = ({ welcome }) => (

  <section className="welcome section--content">
    <div className="bg-yellow"></div>
    <div className="container">
      <div className="row">
        <div className="col-8">
          <h3 className="py-3 py-md-5">{RichText.asText(welcome.title)}</h3>
        </div>
        <div className="col-4 text-right">
          <img className="section--icon" src={welcome.icon.url} alt={welcome.icon.alt} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 py-3 py-md-5">
          {RichText.render(welcome.details)}
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <img className="img-fluid mt-3 mt-md-0" src={welcome.image_1.url} alt={welcome.image_1.alt} />
        </div>
        <div className="col-md-4">
          <img className="img-fluid mt-3 mt-md-0" src={welcome.image_2.url} alt={welcome.image_2.alt} />
        </div>
        <div className="col-md-4">
          <img className="img-fluid mt-3 mt-md-0" src={welcome.image_3.url} alt={welcome.image_3.alt} />
        </div>
      </div>
    </div>
  </section>

);

export default WCWelcome;
